// alert.js
export const SET_ALERT = "SET_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

// auth.js
export const USER_LOADED = "USER_LOADED";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGOUT = "LOGOUT";
export const FORGET_PASSWORD = "FORGET_PASSWORD";
export const RECOVERY_PASSWORD = "RECOVERY_PASSWORD";
export const CHANGE_PROFILE = "CHANGE_PROFILE";
export const AUTH_ERROR = "AUTH_ERROR";

// data.js
export const CLEAR_DATA = "CLEAR_DATA";
export const REFRESH_DATA = "REFRESH_DATA";
export const LOAD_DATA = "LOAD_DATA";
export const ADD_DATA = "ADD_DATA";
export const EDIT_DATA = "EDIT_DATA";
export const END_LOAD = "END_LOAD";
export const DELETE_DATA = "DELETE_DATA";
export const EXPORT_DATA = "EXPORT_DATA";
export const UPLOAD_DATA = "UPLOAD_DATA";
export const CRUD_FAIL = "CRUD_FAIL";

// master.js
export const LOAD_USER = "LOAD_USER";
export const LOAD_ROLE = "LOAD_ROLE";
export const LOAD_MODULE = "LOAD_MODULE";
export const LOAD_ITEM = "LOAD_ITEM";
export const LOAD_BRANCH = "LOAD_BRANCH";
export const LOAD_DEPARTMENT = "LOAD_DEPARTMENT";
export const LOAD_BUSINESSPARTNER = "LOAD_BUSINESSPARTNER";
export const LOAD_SALESINVOICE = "LOAD_SALESINVOICE";
export const LOAD_STOCKSALES = "LOAD_STOCKSALES";
export const LOAD_TRANSFORMATION = "LOAD_TRANSFORMATION";
export const LOAD_CONFIG = "LOAD_CONFIG";
export const LOAD_LOCKSTOCK = "LOAD_LOCKSTOCK";
export const LOAD_LOGS = "LOAD_LOGS";
export const LOAD_DELIVERYORDER = "LOAD_DELIVERYORDER";