import { LOAD_MODULE, LOAD_ROLE, LOAD_USER, LOAD_ITEM, LOAD_BRANCH, LOAD_BUSINESSPARTNER, LOAD_DEPARTMENT, LOAD_SALESINVOICE, LOAD_STOCKSALES, LOAD_TRANSFORMATION, LOAD_DELIVERYORDER, LOAD_CONFIG, LOAD_LOCKSTOCK, LOAD_LOGS } from "../actions/types";

const initialState = {
  user: null,
  role: null,
  module: null,
  item: null,
  branch: null,
  department: null,
  businessPartner: null,
  deliveryOrder: null,
  salesInvoice: null,
  stocksales: null,
  transformation: null,
  config: null,
  lockstock: null,
};

export default function master(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case LOAD_USER:
      return {
        ...state,
        user: payload.data,
      };
    case LOAD_ROLE:
      return {
        ...state,
        role: payload.data,
      };
    case LOAD_MODULE:
      return {
        ...state,
        module: payload.data,
      };
    case LOAD_ITEM:
      return {
        ...state,
        item: payload.data,
      };
    case LOAD_BRANCH:
      return {
        ...state,
        branch: payload.data,
      };
    case LOAD_BUSINESSPARTNER:
      return {
        ...state,
        businessPartner: payload.data,
      };
    case LOAD_DEPARTMENT:
      return {
        ...state,
        department: payload.data,
      };
    case LOAD_SALESINVOICE:
      return {
        ...state,
        salesInvoice: payload.data,
      };
    case LOAD_DELIVERYORDER:
      return {
        ...state,
        deliveryOrder: payload.data,
      };
    case LOAD_STOCKSALES:
      return {
        ...state,
        stocksales: payload.data,
      };
    case LOAD_TRANSFORMATION:
      return {
        ...state,
        transformation: payload.data,
      };
    case LOAD_CONFIG:
      return {
        ...state,
        config: payload.data,
      };
    case LOAD_LOCKSTOCK:
      return {
        ...state,
        lockstock: payload.data,
      };
    case LOAD_LOGS:
      return {
        ...state,
        logs: payload.data,
      };
    default:
      return state;
  }
}
